import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Button from "../../../components/custom-widgets/button";
import Icon from "../../../components/custom-widgets/icon";
import OnlineBusinessBanking from "../../../components/business-banking/online-business-banking";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const SmallBusiness = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-savings-account/small-business/hero-business-savings-account-021023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/is-starting-a-business-worth-it",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const heroChevronData = {
    id: "business-savings-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Happy young bar owner standing at the counter.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "It's Our Job to Help You Make the Most of Your Money"
          }
        },
        {
          id: 2,
          button: {
            id: "sb-savings-hero-cta",
            text: "Open an Account",
            url: "https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=SAVB",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-savings-account",
      title: "Business Savings"
    },
    {
      id: 3,
      active: true,
      title: "Small Business Savings"
    }
  ];

  const title = "Small Business Savings Accounts";

  const SEOData = {
    title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Grow your business capital by opening a small business savings account with WaFd Bank. Only $25 to open and is a simple way to earn interest on your extra cash."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-savings-account/small-business"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-savings-account-021023.jpg"
      }
    ]
  };

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData
  };
  const bizSavings = {
    id: "cta-biz-savings",
    containerClass: "mb-3",
    externalUrl: true,
    target: "blank",
    class: "btn-primary btn-block",
    text: "Open Account",
    url: "https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=SAVB",
    showIcon: false
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="small-business-savings-intro-section" className="container">
        <h1>Small Business Savings Account</h1>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
          <div className="col mb-4 mb-lg-0">
            <h3 className="text-success">
              Grow your business capital by opening a small business savings account with WaFd Bank.
            </h3>
            <Button {...bizSavings} />
            <Link
              id="cd-money-market-rates-link"
              to="/business-banking/business-cd-money-market-rates"
              className="text-decoration-none"
            >
              See Today's Rates <Icon name="arrow-right" class="ml-1" />
            </Link>
          </div>
          <div className="col text-center mb-4 mb-lg-0">
            <img src="../../../images/icons/icon-25-dollars.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">Only $25 initial deposit to open</h3>
            <h4>
              it's a great way to{" "}
              <Link to="/business-banking/business-cd-money-market-rates" id="cd-money-market-rates-link-2">
                earn interest
              </Link>{" "}
              on your extra cash.
            </h4>
          </div>
          <div className="col text-center mb-4 mb-lg-0">
            <img src="../../../images/icons/icon-100-dollars.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">Minimum balance to earn interest $100</h3>
          </div>
          <div className="col text-center mb-4 mb-lg-0">
            <img src="../../../images/icons/icon-slash-5-dollars.svg" alt="" className="mb-3" />
            <h3 className="font-weight-bold mb-0">We will waive the $5 monthly fee</h3>
            <h4>with balances of $200 or more.</h4>
          </div>
        </div>
      </section>

      <OnlineBusinessBanking />
      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" />
    </SecondaryLanding>
  );
};

export default SmallBusiness;
